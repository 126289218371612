import 'magnific-popup';

jQuery( document ).ready(function($) {

    $.magnificPopup.open({
        items: {
          src: '#popup',
          type: 'inline'
        }
    });

    //Apple Form
    $('body').on('click','.btn-apple', function( e ) { 
        e.preventDefault();
        $.magnificPopup.open( {
            items: {
                src: '#apple-popup',
                type: 'inline'
            }
        } );
        var sm = new SMEAppleMusic({
            ae: {
              ae: "6f031bfdb1f3b11f33997bd5fbf79af9c610f313cdae47526986a5c48a00888d",
              brand_id: "4030497",
              segment_id: "2067308",
              activities: '{"actions":{"presave":132538},"mailing_list_optins":{"a0S0800000g8IYsEAM":132539}}'
            },
            am: {
              dev_token: "",
              save_mode: "library",
              custom_playlist_name: "",
              new_apple_presave: true,
              resources: {
                playlists: [],
                albums: []
              }
            },
            sf: {
              form: "396881",
              default_mailing_list: ""
            },
            smf: {
              campaign_id: "396881",
              campaign_key: "6b851a6ff6e0df4d36cce94deb1a0f95"
            }
          });
          document.getElementById("apple_music_form").addEventListener("submit", function (e) {
            e.preventDefault();
            // Grab the email address value.
            var email = document.getElementById("apple_music_email_address").value;
            // Pass the email address and opt in status
            // This can be true or false depending on if the user is forced into the list, or chooses via a checkbox or similar.
            sm.doActions(email, false, ["a0S0800000g8IYsEAM"], false).then(function (res) {
                $.magnificPopup.open( {
                    items: {
                        src: '#thank-you',
                        type: 'inline'
                    }
                } );
            }).catch(function(err) {
                console.log(err);
            });
        });
    } );
    //Spotify / Deezer
    let SMEPreSave = {
        state: null,
        spotify_user: null,
        url_vars: [],
        getURLVars: function (callback, form_id) {
        let hash;
        let hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
        for (let i = 0; i < hashes.length; i++) {
            hash = hashes[i].split('=');
            this.url_vars.push(hash[0]);
            this.url_vars[hash[0]] = hash[1];
            }
        this.state = 'state' in this.url_vars ? this.url_vars['state'] : null;
        this.ae_member_id = 'ae_member_id' in this.url_vars ? this.url_vars['ae_member_id'] : null;
        this.spotify_user = 'spotify_user' in this.url_vars ? this.url_vars['spotify_user'] : null;
        this.deezer_user = 'deezer_user' in this.url_vars ? this.url_vars['deezer_user'] : null;
        this.message = 'message' in this.url_vars ? this.url_vars['message'] : null;
        if (typeof callback === 'function') {
            callback(this.state, this.ae_member_id, this.spotify_user, this.deezer_user, this.message);
        }
        }
    };
    /* String state
        *  The status of the pre-save.
        *  Int ae_member_id
        *  The Appreciation Engine member ID. This can be passed to the forms processor.
        *  String spotify_user
        *  An encrypted string identifying the Spotify user. Can be passed to the pre-save forms processor.
        *  String deezery_user
        *  An encrypted string identifying the Deezer user. Can be passed to the pre-save forms processor.
        */
    SMEPreSave.getURLVars(function (state, ae_member_id, spotify_user, deezer_user, message) {
        // Check the state of the pre-save. If it was successful, the state will either be success or thank-you.
        if (state === 'success' || state === 'thank-you') {
            $.magnificPopup.open( {
                items: {
                    src: '#thank-you',
                    type: 'inline'
                }
            } );
        }
    });

    $('.mfp-inline').magnificPopup({
        type:'inline'
    });
});